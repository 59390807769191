export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function replaceCharsForHTML(text) {
  return text
    .replace(/&/g, "&amp;") // Do this FIRST to avoid overriding other replacements
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&apos;")
    .replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;") // Assuming 1 tab = 4 spaces
    .replace(/\r\n/g, "<br/>") // Windows-style newlines
    .replace(/\n/g, "<br/>") // Unix-style newlines
    .replace(/ /g, "&nbsp;");
}

export function generateSecureRandomString(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  var array = new Uint8Array(length);
  window.crypto.getRandomValues(array);
  for (var i = 0; i < length; i++) {
    result += characters[array[i] % charactersLength];
  }
  return result;
}

export async function playAudioBlob(blob) {
  try {
    let blobUrl = URL.createObjectURL(blob);
    let audio = new Audio(blobUrl);
    await audio.play().catch((error) => {
      console.error("Error trying to play audio: " + error);
    });
  } catch (error) {
    console.error("Error creating audio object: " + error);
  }
}

export function GetFormattedDate() {
  let date = new Date();
  let days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  let day = days[date.getDay()];
  let year = date.getFullYear();
  let month = (date.getMonth() + 1).toString().padStart(2, "0"); // add 1 because months are 0 indexed
  let dayOfMonth = date.getDate().toString().padStart(2, "0");
  return `${day} ${year}-${month}-${dayOfMonth}`;
}

export function GetFormattedTime() {
  let date = new Date();
  let hours = date.getHours().toString().padStart(2, "0");
  let minutes = date.getMinutes().toString().padStart(2, "0");
  let seconds = date.getSeconds().toString().padStart(2, "0");
  let milliseconds = Math.floor(date.getMilliseconds() / 10)
    .toString()
    .padStart(2, "0"); // divide by 10 to get the first two digits of the milliseconds
  return `${hours}:${minutes}:${seconds}.${milliseconds}`;
}
