// SuperTerminal class
export class SuperTerminal {
  constructor(terminalElement) {
    // private properties
    this.terminal = terminalElement;

    this.callbackCommand = null; // to store user callback function
    this.callbackInput = null; // to store user callback function

    this.commandHistory = [];
    this.commandHistoryIndex = -1;
    this.promptCommand =
      '<div class="inputRow"><span>S:\\></span><textarea class="inputCommand" spellcheck="false" autocapitalize="none"></textarea></div>';
    this.promptText =
      '<div class="inputRow"><span>></span><textarea class="inputTextQuestion" spellcheck="false" autocapitalize="none"></textarea></div>';
    this.promptSelect =
      '<div class="inputRow"><span>></span><textarea class="inputSelect" spellcheck="false" autocapitalize="none"></textarea></div>';
    this.userInputText = "";
    this.userInputSelect = "";

    this.terminal.addEventListener("click", this.handleClick.bind(this));
    this.terminal.addEventListener("keydown", this.handleKeydown.bind(this));
  }

  // Method for registering a callback
  registerCallbackCommand(callback) {
    if (typeof callback === "function") {
      this.callbackCommand = callback;
    } else {
      console.error("Error: Callback is not a function");
    }
  }

  // Method for registering a callback
  registerCallbackInput(callback) {
    if (typeof callback === "function") {
      this.callbackInput = callback;
    } else {
      console.error("Error: Callback is not a function");
    }
  }

  //
  // methods
  //
  adjustTextareaHeight(textarea) {
    textarea.style.height = "0px";
    let numPixels = textarea.scrollHeight - 8;
    textarea.style.height = `${numPixels}px`;
  }

  handleClick(e) {
    let inputs = this.terminal.getElementsByClassName("inputCommand");
    let lastInput = inputs[inputs.length - 1];
    lastInput.focus();
  }

  handleKeydown(e) {
    // first check for command history keys
    if (e.key === "ArrowUp") {
      // Navigate back in command history
      if (this.commandHistoryIndex < this.commandHistory.length - 1) {
        this.commandHistoryIndex++;
        e.target.value =
          this.commandHistory[
            this.commandHistory.length - 1 - this.commandHistoryIndex
          ];
      }
    } else if (e.key === "ArrowDown") {
      // Navigate forward in command history
      if (this.commandHistoryIndex > -1) {
        this.commandHistoryIndex--;
        if (this.commandHistoryIndex === -1) {
          this.commandHistoryIndex = 0;
        }
        e.target.value =
          this.commandHistory[
            this.commandHistory.length - 1 - this.commandHistoryIndex
          ];
      }
    } else if (e.key === "Escape") {
      this.commandHistoryIndex = -1;
      e.target.value = "";
    }

    if (e.key === "Enter") {
      e.preventDefault(); // Prevents the addition of a new line

      let curUserInput = e.target.value;
      let curClassNameFull = e.target.className;
      let curClassNamePrefix = curClassNameFull;
      let curClassNameSuffix = null;

      // lets see if we can find the prefix
      let searchPrefix = "inputText";

      // console.log("curClassNameFull: " + curClassNameFull);

      let searchIndex = curClassNameFull.indexOf(searchPrefix);
      if (searchIndex !== -1) {
        curClassNamePrefix = curClassNameFull.substring(0, searchPrefix.length);
        curClassNameSuffix = curClassNameFull.substring(
          searchPrefix.length,
          curClassNameFull.length
        );
      }

      switch (curClassNamePrefix) {
        case "inputCommand":
          let inputCommand = curUserInput.trim().toLowerCase();
          if (inputCommand.length > 0) {
            // Add command to history and reset commandHistoryIndex
            this.commandHistory.push(inputCommand);
            this.commandHistoryIndex = -1;

            // todo - need to let the user register a callback function
            this.callbackCommand(inputCommand, this);
          } else {
            // handle empty command
            this.writePromptCommand();
          }
          break;

        case "inputText":
          this.callbackInput(curClassNameSuffix, curUserInput, this);
          break;
      }
    }
  }

  // Rest of your existing functions turned into methods...
  setInputOnLastCommand(className) {
    let newInput = this.terminal.querySelectorAll(
      ".inputRow:last-child ." + className
    );
    if (newInput.length > 0) {
      newInput = newInput[newInput.length - 1];

      const that = this;
      newInput.addEventListener("input", function () {
        that.adjustTextareaHeight(newInput);
      });

      newInput.addEventListener("scroll", function () {
        that.adjustTextareaHeight(newInput);
      });
      newInput.focus();
    }
  }

  writeLine(text) {
    let outputLine = document.createElement("div");
    outputLine.innerHTML = text;
    outputLine.classList.add("textRow");
    this.terminal.appendChild(outputLine);
  }

  writeError(textError) {
    this.writeLine("Error: <span>" + textError + "</span>");
  }

  writePromptCommand() {
    this.writeLine(this.promptCommand);
    this.setInputOnLastCommand("inputCommand");
  }

  writePromptText() {
    this.writeLine(this.promptText);
    this.setInputOnLastCommand("inputTextQuestion");
  }

  writeImageLine(path, alt, height, className) {
    let pictureElement = document.createElement("img");
    pictureElement.src = path;
    pictureElement.alt = alt;
    pictureElement.style.height = height;

    let pictureLine = document.createElement("div");
    pictureLine.appendChild(pictureElement);
    pictureLine.classList.add(className);
    this.writeLine(pictureLine.innerHTML);
  }

  writeLoadingImage() {
    // <img src="./static/robot-loading.gif" class="loadingImage" alt="Thinking..." title="Thinking..." style="height:1.5rem;">
    this.writeLine(
      '<div class="inputRow inputCommand">Ting...<br/>Hello!!!<textarea class="inputLoading" spellcheck="false" autocapitalize="none"></textarea></div>'
    );
    this.setInputOnLastCommand("inputLoading");
  }

  //
  // functions for some commands
  //
  clearAllLines() {
    // Select all divs in the body
    let divs = document.querySelectorAll("body div");

    // remove all except first. kind of hacky.
    for (let i = 1; i < divs.length; i++) {
      //
      divs[i].parentNode.removeChild(divs[i]);
    }
  }

  clearLastLine() {
    let divs = document.querySelectorAll("body div");
    divs[divs.length - 1].parentNode.removeChild(divs[divs.length - 1]);
  }
}
