import { replaceCharsForHTML } from "./utils.js";
import { GetFormattedDate, GetFormattedTime } from "./utils.js";

// globals
let buildNumber = "54";

export async function processCommand(command, superTerminal, writePrompt)
{
  let date = new Date();
  switch (command)
  {
    case "date":
      superTerminal.writeLine("Date is " + GetFormattedDate());
      superTerminal.writeLine("<br/>");
      superTerminal.writePromptCommand();
      break;

    case "time":
      superTerminal.writeLine("Time is " + GetFormattedTime());
      superTerminal.writeLine("<br/>");
      superTerminal.writePromptCommand();
      break;

    case "cls":
      superTerminal.clearAllLines();
      superTerminal.writePromptCommand();
      break;

    case "exit":
    case "refresh":
    case "reboot":
    case "r":
      top.frames.location.reload();
      break;

    case "dir":
    case "ls":
    case "ll":
      let dirString =
        "\n\
 Volume is STEVE\n\
 Directory of S:\\\n\
\n\
914      COM      914\n\
CHIRP    BAT       47\n\
DONTRUN  COM      666\n\
GITHUB   EXE   161803\n\
INSTA    COM    21718\n\
LILY     COM    12309\n\
LINKEDIN COM     9146\n\
STRAVA   COM    31415\n\
WILLIAM  EXE    82011\n\
           8 file(s)\n\
\n\
";
      let htmlString = replaceCharsForHTML(dirString);

      superTerminal.writeLine(htmlString);
      superTerminal.writePromptCommand();
      break;
    case "c":
    case "chirp":
      window.open("https://www.pomona.edu/", "_blank");
      superTerminal.writePromptCommand();
      break;

    case "github":
      window.open("https://github.com/siverson914", "_blank");
      superTerminal.writePromptCommand();
      break;

    case "dontrun":
      window.open("https://www.youtube.com/watch?v=dQw4w9WgXcQ", "_blank");
      superTerminal.writePromptCommand();
      break;

    case "insta":
      window.open("https://www.instagram.com/bwana914", "_blank");
      superTerminal.writePromptCommand();
      break;

    case "strava":
      window.open("https://www.strava.com/athletes/siverson914", "_blank");
      superTerminal.writePromptCommand();
      break;

    case "linkedin":
      window.open("https://www.linkedin.com/in/steveiverson/", "_blank");
      superTerminal.writePromptCommand();
      break;

    case "hello":
    case "hi":
      superTerminal.writeLine(`Hello.`);
      superTerminal.writePromptCommand();
      break;

    case "ver":
      superTerminal.writeLine(`Steve CLI v9.14.` + buildNumber);
      superTerminal.writePromptCommand();
      break;

    case "william":
      superTerminal.writeImageLine(
        "./static/William.jpg",
        "William",
        "10.0rem",
        "image"
      );
      superTerminal.writePromptCommand();
      break;

    case "lily":
      superTerminal.writeImageLine(
        "./static/Lily.jpg",
        "Lily",
        "10.0rem",
        "image"
      );
      superTerminal.writePromptCommand();
      break;

    case "914":
      superTerminal.writeImageLine(
        "./static/914.jpg",
        "914",
        "10.0rem",
        "image"
      );
      superTerminal.writePromptCommand();
      break;

    case "help":
      let helpString =
        "\n\
DATE     Display the current date.\n\
TIME     Display the current time.\n\
CLS      Clear the screen.\n\
DIR      Display the list of files.\n\
HELP     Show available commands.\n\
VER      Display the version.\n\
REBOOT   Reboot the system.\n\
\n\
";
      helpString = replaceCharsForHTML(helpString);

      superTerminal.writeLine(helpString);
      superTerminal.writePromptCommand();
      break;

    default:
      superTerminal.writeLine(`Syntax error. HELP for help.<br/><br/>`);
      superTerminal.writePromptCommand();
      break;
  } // end switch
}
