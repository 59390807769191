import { SuperTerminal } from "./SuperTerminal.js";
import { processCommand } from "./processCommand.js";
import { processInput } from "./processInput.js";
import { GetFormattedDate, GetFormattedTime } from "./utils.js";

// setup SuperTerminal
let terminalElement = document.querySelector("#terminal");

let superTerminal = new SuperTerminal(terminalElement);
superTerminal.registerCallbackCommand(processCommand, superTerminal);
superTerminal.registerCallbackInput(processInput, superTerminal);

// Write initial prompt
(async () => {
  await setupApp();
})();

//
// functions
//
async function setupApp() {
  superTerminal.writeLine("Steve Iverson v9.14");
  superTerminal.writeLine("Copyright (C) 1975");
  superTerminal.writeLine("<br/>");
  superTerminal.writeLine("Date is " + GetFormattedDate());
  superTerminal.writeLine("Time is " + GetFormattedTime());
  superTerminal.writeLine("<br/>");
  superTerminal.writePromptCommand();
}
